<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <transition name="slide-fade">
                    <div v-if="show">
                        <p>{{ $t('Public.UBIService.banner.title') }}</p>
                        <div class="banner-txt">{{ $t('Public.UBIService.banner.text') }}</div>
                    </div>
                </transition>
            </div>
            <div class="content">
                <div class="big_data">
                    <p class="detail_title">{{ $t('Public.UBIService.lovBigData.title') }}</p>
                    <div class="data_service">
                        <ul>
                            <li v-for="(item, index) in $t('Public.UBIService.lovBigData.list')" :key="index">
                                <p>{{ item.title }}</p>
                                <span></span>
                                <div>{{ item.text }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="hardware" v-show="0">
                    <p class="detail_title">{{ $t('Public.UBIService.intelVehicle.title') }}</p>
                    <div class="vehicle">
                        <ul>
                            <li>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/obd.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>OBD</b>
                                        </div>
                                        <p>On Board Diagnostics</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>OBD</b>
                                            <span>(On Board Diagnostics)</span>
                                        </div>
                                        <p>车载诊断系统</p>
                                    </template>
                                </div>
                            </li>
                            <li class="active">
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>DMS</b>
                                        </div>
                                        <p>Driver monitor system</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <span>(Driver monitor system)</span>
                                            <b>DMS</b>
                                        </div>
                                        <p>驾驶员状态监测系统</p>
                                    </template>
                                </div>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/dms.png" /></div>
                            </li>
                            <li>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/dvr.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>DVR</b>
                                        </div>
                                        <p>Digital Video Recorder</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>DVR</b>
                                            <span>(Digital Video Recorder)</span>
                                        </div>
                                        <p>车载硬盘录像仪</p>
                                    </template>
                                </div>
                            </li>
                            <li class="active">
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>ADAS</b>
                                        </div>
                                        <p>Advanced Driving Assistance System</p>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <span>(Advanced Driving Assistance System)</span>
                                            <b>ADAS</b>
                                        </div>
                                        <p>高级驾驶辅助系统</p>
                                    </template>
                                </div>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/adas.png" /></div>
                            </li>
                            <li>
                                <div class="vehicle_icon"><img src="../../public/images/service/ubi/lanya.png" /></div>
                                <div class="vehicle_text">
                                    <template v-if="$i18n.locale == 'EN'">
                                        <div>
                                            <b>Bluetooth Beacon</b>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <b>Bluetooth Beacon</b>
                                        </div>
                                        <p>蓝牙信标</p>
                                    </template>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'UBIService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            show: false,
            src: ''
        };
    },
    methods: {},
    mounted() {
        this.show = true;
    }
};
</script>
<style scoped>
.banner-txt {
    text-align: justify;
}
.galileo {
    padding: 1rem 3.6rem 0;
}
.galileo_introduce {
    line-height: 2;
    margin: 0.73rem 0 0.7rem;
    font-size: 0.2rem;
    font-weight: 400;
    color: #333333;
}
.galileo_img {
    width: 11.55rem;
    margin: 0 auto;
}
.hardware {
    width: 100%;
    height: 10rem;
    padding: 1rem 0 1.66rem;
    background: #f6fbff;
}
.data_service {
    width: 100%;
    padding: 1.25rem 3.6rem 1.7rem;
    position: relative;
}
.vehicle {
    width: 100%;
    margin-top: 1.06rem;
    position: relative;
    padding-top: 0.76rem;
}
.data_service::before,
.vehicle::before {
    display: block;
    content: '';
    width: 6.6rem;
    height: 6.6rem;
    background: url(../../public/images/service/ubi/big_data.png) no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
}
.vehicle::before {
    width: 6.64rem;
    height: 5.09rem;
    background: url(../../public/images/service/ubi/danao.png) no-repeat;
    background-size: 100% 100%;
}
.data_service li {
    position: absolute;
    top: 1.47rem;
    left: 3.5rem;
    z-index: 20;
    width: 3.68rem;
    min-height: 2.24rem;
    background: #ffffff;
    box-shadow: -0.06rem 0.07rem 0.3rem 0px rgba(0, 0, 0, 0.15);
    padding: 0.3rem;
    font-size: 0.16rem;
    color: #666;
    transition: all 0.6s;
}
.data_service li:hover {
    background-color: #e8f8ff;
    cursor: pointer;
}
.vehicle li {
    position: absolute;
    top: 0;
    left: 9.06rem;
    z-index: 20;
    display: flex;
    align-items: center;
    width: 5rem;
}
.vehicle li.active {
    justify-content: flex-end;
    text-align: right;
}
.vehicle li.active b {
    margin: 0 0 0 0.1rem;
}
.vehicle li:nth-child(2) {
    top: 1.8rem;
    left: 1.7rem;
}
.vehicle li:nth-child(3) {
    top: 1.8rem;
    left: 12.38rem;
}
.vehicle li:nth-child(4) {
    top: 4.33rem;
    left: 1.7rem;
}
.vehicle li:nth-child(5) {
    top: 4.33rem;
    left: 12.38rem;
}
.vehicle_text {
    font-size: 0.12rem;
}
.vehicle_text b {
    font-size: 0.2rem;
    font-weight: 500;
    margin-right: 0.1rem;
}
.vehicle_text p {
    font-size: 0.14rem;
    color: #666666;
    margin-top: 0.03rem;
}
.vehicle_icon {
    width: 0.58rem;
    margin: 0 0.15rem;
}
.data_service li:nth-child(2) {
    left: 11.92rem;
}
.data_service li:nth-child(3) {
    top: 5.2rem;
}
.data_service li:nth-child(4) {
    left: 11.92rem;
    top: 5.2rem;
}
.data_service li p {
    font-size: 0.22rem;
    font-weight: 500;
    color: #333333;
}
.data_service li div {
    text-align: justify;
    line-height: 1.5;
}
.data_service li:nth-child(2) div {
    text-align: left !important;
}
.data_service li span {
    display: inline-block;
    width: 0.44rem;
    height: 0.06rem;
    background: #05a3e4;
    margin: 0.07rem 0 0.2rem;
}
.banner {
    width: 100%;
    height: 5.76rem;
    padding: 1.8rem 3rem 0 3.6rem;
    font-size: 0.22rem;
    font-weight: 300;
    background: url(../../public/images/service/ubi/banner.jpg) no-repeat;
    background-size: 100% 100%;
    line-height: 2;
    background-color: #31575c;
}
.banner p {
    font-size: 0.54rem;
    line-height: 1.39;
    margin-bottom: 0.2rem;
}
.content {
    padding: 1rem 0 2rem;
    color: #333;
    padding-bottom: 1rem;
}
.detail_title {
    font-size: 0.32rem;
    font-weight: 600;
    text-align: center;
}
.service_type {
    margin-top: 1rem;
}
.service_type li {
    width: 100%;
    display: flex;
    padding: 0.56rem 3.5rem 0.5rem;
    position: relative;
    min-height: 3.8rem;
}
.service_type li:nth-child(2) {
    background: url(../../public/images/service/li2_bg.png) no-repeat;
    background-size: 100% 100%;
}
.service_type li:nth-child(4) {
    background: url(../../public/images/service/li4_bg.png) no-repeat;
    background-size: 100% 100%;
}
.service_type li:nth-child(1)::after,
.service_type li:nth-child(3)::after {
    position: absolute;
    bottom: 0;
    left: 4.07rem;
    z-index: -50;
    display: block;
    content: '';
    background: url(../../public/images/service/01.png) no-repeat;
    background-size: 100% 100%;
    width: 1.69rem;
    height: 1.46rem;
}
.service_type li:nth-child(2)::after,
.service_type li:nth-child(4)::after {
    left: 12.6rem;
}
.service_type li:nth-child(3)::after {
    background: url(../../public/images/service/03.png) no-repeat;
    background-size: 100% 100%;
}
.service_text {
    width: 7.2rem;
    margin-right: 0.2rem;
}
.service_img {
    width: 4.4rem;
}
.service_type li.active .service_text {
    width: 6.1rem;
}
.service_type li.active .service_img {
    margin-right: 0.62rem;
}
.service_type li.active .service_text_top {
    justify-content: flex-end;
}
.service_type li.active .service_text_top div {
    margin: 0 0 0 0.2rem;
}
.service_type li.active .service_text_middle,
.service_type li.active .service_text_bottom {
    padding: 0 0.76rem 0 0;
    text-align: right;
}
.service_text_top {
    display: flex;
    align-items: center;
    font-size: 0.26rem;
    padding-top: 0.43rem;
    padding-left: 0.5rem;
}
.service_text_top div {
    width: 0.6rem;
    margin-right: 0.2rem;
}
.service_text_middle {
    font-size: 0.16rem;
    color: #666;
    padding-left: 1.3rem;
    line-height: 2;
    text-align: justify;
}
.service_text_bottom {
    font-size: 0.18rem;
    margin-top: 0.28rem;
    padding-left: 1.3rem;
}
.service_type li.active .service_text_bottom,
.service_type li:nth-child(3) .service_text_bottom {
    margin-top: 0.5rem;
}
.service_text_bottom:hover {
    color: #05a3e4;
    cursor: pointer;
}
</style>
